import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { StaticImage } from "gatsby-plugin-image";
import Typography from "../../components/Typography/Typography";
import MainLayout from "../../components/mainlayout";
import Summary from "../../components/CaseStudy/Summary/Summary";
import { mobileViewBreakpoint } from "../../components/helper";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: "0px -16px"
  },
  caseStudyContainer: {
    boxSizing: "border-box",
    padding: "0px 32px",
    maxWidth: "1234px",
    margin: "80px auto 0px auto",
    "& > h2": {
      marginBottom: "28px",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },
    "& > h5": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px 16px 28px 16px",
      margin: "80px auto 0px auto"
    }
  }
}));

const Article = () => {
  const classes = useStyles();
  const theme = useTheme();

  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(relativePath: { eq: "casestudy/nba/basket.jpg" }) {
        childImageSharp {
          fixed(width: 800, height: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <MainLayout
      title={"NBA India Games"}
      h1
      banner={
        <StaticImage
          src="../../assets/images/casestudy/nba/basket.jpg"
          draggable="false"
          alt="basketball ring with net"
        />
      }
      metaTitle="NBA India Games 2019 Case Study"
      metaDescription="Click to read how Oour team created a multi - city VIP transport solution for our client, NBA that included 1 25 + mixed fleet vehicles."
      shareImage={data.bannerImage.childImageSharp.fixed.src}
      breadcrumbs={[{ label: "NBA India Games" }]}
    >
      <div className={classes.mainContainer}>
        <div className={classes.caseStudyContainer}>
          <Typography customVariant={"h2Bold"}>
            NBA India Games, 2019
          </Typography>

          <Typography customVariant={"h5Regular"}>
            NBA India on December 20, 2018, had announced that the first - ever
            NBA game in the country would be organized on October 4 and 5 in
            Mumbai. The NBA India Games 2019 will feature the Sacramento Kings
            and Indiana Pacers, who will play two pre - season games on October
            4 and 5 at the Dome, NSCI, SVP Stadium
          </Typography>
        </div>
        <Summary
          title={"Overview"}
          fullHeight={false}
          isFlipped={false}
          image={
            <StaticImage
              src="../../assets/images/casestudy/F1_Case_Study_Overview_Image.jpg"
              draggable="false"
              alt="prople waiting for transport"
            />
          }
          link={undefined}
          description={
            <>
              <p>
                Our team created a multi - city VIP transport solution for our
                client, National Basketball Association (NBA) that included 125
                + mixed fleet vehicles.
              </p>

              <p>
                Overview We had multiple teams working in parallel in Agra,
                Delhi, and Mumbai who were embedded with NBA staff to assist
                with government approvals, supplier and VIP access’, and
                coordination with local and federal agencies for assistance with
                protocol and secure transport routes.
              </p>

              <p>
                Our teams managed all ground transportation requests and
                produced the complex routing and operations plans for
                accommodation and venue ingress, egress, staging, and
                contingency planning.
              </p>
            </>
          }
        />

        <Summary
          title={"Management"}
          fullHeight={false}
          isFlipped={true}
          image={
            <StaticImage
              src="../../assets/images/casestudy/F1_Case_Study_Elements_Image.jpg"
              draggable="false"
              alt="car parking bird view"
            />
          }
          link={undefined}
          description={
            <p>
              To design and plan this event, we needed to conduct several tasks
              in parallel with managing internal and external stakeholders and
              suppliers. Our procurement and commercial secured 125 + mixed
              fleet units, while the transport managers organized fleet
              allocations and usage models based on the daily transfers,
              arrivals, and departures manifest.
            </p>
          }
        />

        <Summary
          title={"Challenges"}
          fullHeight={false}
          isFlipped={false}
          image={
            <StaticImage
              src="../../assets/images/casestudy/F1_Case_Study_Outcomes_Image.jpg"
              draggable="false"
              alt="white bus at marina circuit"
            />
          }
          link={undefined}
          description={
            <>
              <p>
                Multi - City fleet allocation was the most significant challenge
                as it was critical to maintain the highest levels of services
                and equipment standards while staying within budget. The second
                challenge was the ever - changing requirement for players and ad
                hoc NBA teams and guests to attend simultaneous events within
                the invited group.
              </p>
            </>
          }
        />

        <Summary
          title={"Solutions"}
          fullHeight={false}
          isFlipped={true}
          image={
            <StaticImage
              src="../../assets/images/casestudy/F1_Case_Study_Legacy_Image.jpg"
              draggable="false"
              alt="F1 car"
            />
          }
          link={undefined}
          description={
            <>
              <p>
                Our solution was to maintain multiple fleet systems with a
                guaranteed on - call contingency whose drivers were already
                vetted and trained in customer service. We also conducted run
                cuts on primary, secondary, and tertiary routes in all cities.
                Additional fleet dispatchers were activated to cover the multi -
                city groups, with airport operations incorporated into 24 - hour
                service coverage to always accommodate all guest requests .
              </p>
            </>
          }
        />
      </div>
    </MainLayout>
  );
};

export default Article;
